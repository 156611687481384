.individuals-section {
    width: 100%;
    height: auto; 
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #7c6d19, #422803); 
    border-bottom-left-radius: 120px;


  }

  .individuals-section  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }
  
  .individuals-section .text {
    padding:  20px;
    color: #f2eded;
  }
  
 .individuals-section .text h1 {
    font-size: 36px;
    margin-bottom: 15px;
    color: #dfd3d3;
  }
  
  .individuals-section .text p {
    font-size: 18px;
  }
  
  .individuals-section .image {
    width: 100%;
    height: auto;
  
    padding-right: 20px;
    position: relative;
    top: 30%;
    bottom: 0;
  }
  
  .individuals-section .image img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px; /* Optional: Add border radius to the image */
    position: relative;
    top: 50px;
    bottom: 50;
  }

  @media(max-width:768px){
    .individuals-section{
      position: relative;
      margin-top: 30%;
      display: block;
    }
    .individuals-section   .content {
      flex-direction: column;
      position: relative;
      margin-top: 20px;
      clear: both;
    }
    .individuals-section  .text{
      position: relative;
    }


  
  }