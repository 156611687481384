.products-wrapper{
    width: 100%;
    height: 250px; 
    background: #fff;
    align-items: center;
    justify-content: center;
    margin-top: 70px;

}
.products{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

}
.product{
    border-right: #eee solid ;
    margin-left: 20px;
    max-width: 80%;
    
}

.product-icon{
    color:#3a077d;
    font-size: 50px;
    padding: auto;
    text-align: left;
    margin-bottom: 25px;
}
.product-title{
    color:#000;
    font-size: 20px;
    text-align: left;
    font-weight: 700;
    font-family: 'Arial'
}

.product-text{
    color:#000;
    font-size: 16px;
    text-align: left;
    font-weight: 300;
    font-family: 'Arial';
    opacity: 0.7;
    
}

@media (max-width: 768px) {
    .products{
      flex-direction: column;
      margin-bottom: 30%;
    
    }

    .product{
       margin-top: 10%;
       margin-bottom: 10%;
       position: relative;
        
}
}
