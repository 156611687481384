.hero-section {
    width: 100%;
    height: auto; /* Adjust height as needed */
    background: linear-gradient(135deg, #ff9900, #ff00ff); /* Example gradient colors */
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 120px;
  }
  
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }
  
  .text {
    flex: 1;
    padding: 0 20px;
    color: #fff;
  }
  
  .text h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .text p {
    font-size: 18px;
  }
  
  .image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
  
  .image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px; /* Optional: Add border radius to the image */
  }

  .credit-card-wrapper{
   position: absolute;
   top: 50%;
   transform: rotateX(20deg) rotateY(10deg);
  }
  .smart-phone-position{
    transform: rotateX(-20deg) rotateY(-10deg);
    position: absolute;
    top: 30px;

  }

  @media (max-width: 768px) {

    .credit-card-wrapper {
     display: none; 
    }

    .smart-phone-position {
     display: none;
    }

    .content {
      flex-direction: column;
    }

    .image {
     display: none;
    }
  
  }


  