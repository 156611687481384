.table-banking-section {
    width: 100%;
    height: auto; 
    background: #fff;
    align-items: center;
    justify-content: center;
  }


  
  .table-banking-section .text {
    padding: 20px;
    color: #1c0a0a;
  }
  
 .table-banking-section .text h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #211f1f;
  }
  
  .table-banking-section .text p {
    font-size: 18px;
  }
  
  .table-banking-section .image {
   
    justify-content: flex-end;
    padding-right: 20px;
  }
  
  .table-banking-section .image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px; /* Optional: Add border radius to the image */
  }

  @media(max-width:768px){
    .table-banking-section{
      position: relative;
      margin-top: 30%;
      z-index: 1;
    }
    .content {
      flex-direction: column;
      position: relative;
      margin-top: 20px;
      clear: both;
    }
    .text{
      position: relative;
    }

    .image {
     display: none;
    }
  }