.chama-section {
    width: 100%;
    height: auto; 
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #050227, #65468d); 
    border-top-right-radius: 120px;


  }


  
  .chama-section .text {
    flex: 1;
    padding:  20px;
    color: #f2eded;
  }
  
 .chama-section .text h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #dfd3d3;
  }
  
  .chama-section .text p {
    font-size: 18px;
  }
  
  .chama-section .image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .chama-section .image img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px; /* Optional: Add border radius to the image */
  }

  @media(max-width:768px){
    .chama-section {
      position: relative;
      margin-top: 30%;
      z-index: 1;
    }
    .chama-section   .content {
      flex-direction: column;
      position: relative;
      margin-top: 20px;
      clear: both;
    }
    .chama-section  .text{
      position: relative;
    }
   


  
  }