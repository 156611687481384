.smartphone {
    width: 290px;
    height: 500px;
    background-color: #3a077d;
    border-radius: 30px;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
    margin: 30px auto;
    margin-top: 20%;
  }

  .phone-top{
    width: 260px;
    height: 50px;
    background-color: blueviolet;
    border-radius: 15px;
    align-items: center;
    margin-top: -15%;
    margin-left: auto;
    margin-right: 20px;

  }
  
  .screen {
    width: 100%;
    height: 80%;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    position: relative;
    overflow: hidden;
  }

  .screen-trans{
    font-size: 16px;
    text-align: left;
    margin-top: 20%;
    margin-left: 20px;
    line-height:normal;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .bal-container{
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    color: green;

  }
  .money-out{
    color:red;
  }
  .money-in{
    color:green;
  }
  
  .button {
    width: 60px;
    height: 10px;
    background-color: #333;
    border-radius: 5px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .camera {
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
  }