.merrygo-section {
    width: 100%;
    height: auto; 
    background: #fff;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
  }

  .merrygo-section  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }
  
  .merrygo-section .text {
    flex: 1;
    padding: 0 20px;
    color: #1c0a0a;
  }
  
 .merrygo-section .text h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #211f1f;
  }
  
  .merrygo-section .text p {
    font-size: 18px;
  }
  
  .merrygo-section .image {
    flex: 1;
    justify-content: flex-end;
    padding-right: 20px;
    height: 70%;
    width: 70%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .merrygo-section .image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px; /* Optional: Add border radius to the image */
  }

  @media(max-width:768px){
    .merrygo-section{
      position: relative;
      margin-top: 30%;
      z-index: 1;
    }
    .merrygo-section .content {
      flex-direction: column;
      position: relative;
    }

    .merrygo-section .image {
     display: none;
    }
  }