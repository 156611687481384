.video-section {
    position: relative;
    width: 100%;
    margin-top: 100px; /* Adjust top margin as needed */
    margin-bottom: 50px; /* Adjust bottom margin as needed */
  }
  
  .video {
    width: 60%;
    display: block;
    margin: 0 auto;
  }
  
  .overlay {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .overlay h1 {
    font-size: 36px;
    margin: 0;
  }
  
  .overlay p {
    font-size: 18px;
  }
  
  .video-caption {
    position: absolute;
    bottom: 20px; /* Adjust bottom spacing as needed */
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background color for better readability */
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 5px;
  }

  @media(max-width:768px){
    .video-section{
        display: none;
    }
  }
  