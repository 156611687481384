.why-mastakash-section {
    width: 100%;
    height: auto; 
    background: #fff;
    align-items: center;
    justify-content: center;
  }


  
  .why-mastakash-section .text {
 
    padding: 0 20px;
    color: #1c0a0a;
  }
  
 .why-mastakash-section .text h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #211f1f;
  }
  
  .why-mastakash-section .text p {
    font-size: 18px;
  }
  
  .why-mastakash-section .image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .why-mastakash-section .image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px; /* Optional: Add border radius to the image */
  }