.founder-section {
    width: 100%;
    height: auto; 
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff ;


  }

  .founder-section  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }

  .founder-section .wraper {
    flex: 1;
    padding: 0 20px;
    color: #1b0404;
  }

  .founder-section .wraper h1,h2,h3,h4 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #1c1919;
  }
  
 .founder-section p {
    font-size: 18px;
    color: #191515;
  }
  .founder-section .row{
    margin-top: 40px;
    margin-bottom: 40px;
  }