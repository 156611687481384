.coin-stack {
    display: flex;
    justify-content: center;
  }
  
  .coin {
    width: 100px; /* Adjust size as needed */
    height: 80px; /* Adjust thickness as needed */
    background: linear-gradient(90deg, #ffcc00, #ff9900); /* Example gradient colors */
    border-radius: 50%;
    margin-bottom: 10px; /* Adjust spacing between coins */
  }

 .coin-1{
    position: absolute;
    left: 50%;
    top:40px;
  }
  