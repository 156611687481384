.about-section {
    width: 100%;
    height: auto; 
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #050227, #65468d); 
    border-top-right-radius: 120px;


  }

  .about-section  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }

  .about-section .wraper {
    flex: 1;
    padding: 0 20px;
    color: #f2eded;
  }

  .about-section .wraper h1,h2,h3,h4 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #cec3c3;
  }
  
 .about-section p {
    font-size: 18px;
    color: #bbafaf;
  }
  .about-section .row{
    margin-top: 40px;
    margin-bottom: 40px;
  }