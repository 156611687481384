.unlimited-solutions {
    width: 80%;
    height: auto; 
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 50px;
  }

  .flex-parent{  
    color: black ;
    text-align: center;
    display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;
      align-content: space-evenly;
  }

  .flex-child {
    padding: 0px 36px;
    text-align: center;
    color: #292323;
}

.fd-icon {
    font-size:18px;
   color:white;
   background-color: rgb(78, 17, 135);
   border: 1px solid grey;
   border-radius: 60px;
   padding:10px;
}
.flex-heading{
    text-align: center;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: stretch;
}
.flex-heading > h3{
    font: size 14px;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
.flex-heading > p{
    font: size 14px;
    font-weight: 500;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.benefits-wrapper{
    background-color: rgb(235, 245, 241);
    padding: 20px;
  }

  .flex-child img{
    margin-right: 50px;
  }

  .feature-wrapper{
    background-color: rgb(241, 232, 237);
    padding: 20px;
  }