.credit-card {
    width: 360px;
    height: 220px;
    background: linear-gradient(135deg, #6744b2, #3a077d); /* Example gradient colors */
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    
  }
  
  .chip {
    width: 60px;
    height: 40px;
    background: url('https://www.theaccessgroup.com.au/content/themes/accessgroup/images/icons/visa.svg') no-repeat;
    background-size: contain;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .card-number {
    color: white;
    font-family: Arial, sans-serif;
    font-size: 20px;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .card-holder,
  .valid-thru {
    color: white;
    font-family: Arial, sans-serif;
    font-size: 14px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  
  .valid-thru {
    bottom: 50px;
  }

  .card-brand
  {
    color: rgb(157, 149, 182);
    font-weight: 600;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px;
    position: absolute;
    top: 20px;
    left: 20px;
  }