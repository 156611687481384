.interoperability-section {
    width: 100%;
    height: auto; /* Adjust height as needed */
    background: linear-gradient(135deg, #6f2da9, #3a077d); /* Example gradient colors */
    align-items: center;
    justify-content: center;
    border-top-right-radius: 120px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }
  
  .text {
    flex: 1;
    padding: 0 20px;
    color: #fff;
  }
  
  .text h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #fff;
  }
  
  .text p {
    font-size: 18px;
  }
  
  .image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
  
  .image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px; /* Optional: Add border radius to the image */
  }
  @media(max-width:768px){
    .interoperability-section {
      position: relative;
      margin-top: 30%;
      z-index: 1;
    }
    .content {
      flex-direction: column;
      position: relative;
    }
    .text{
      position: relative;
      flex-direction: column;
    }

    .image {
     display: none;
    }
  }